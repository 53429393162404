import React from 'react'

const LogoutLayout = (props) => {
  return (
    <>
       {props.children}
    </>
  )
}

export default LogoutLayout
